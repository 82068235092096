<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img :src="require('@/assets/apply-logo-dark.png')" contain max-width="128" min-height="78" width="100%" />

      <base-title size="text-body-1" space="4" title="BUILDING A NEW CLASS OF TENANTS" weight="regular" />

      <base-body>
        At Apply Oregon we leverage 30 years of experience in the rental
        screening and tech world of Pacific Screening with the extensive
        tenant education of Rent Well to bridge the gaps between
        property managers and applicants.
      </base-body>

      <base-btn class="mb-12" color="white" outlined
        href="https://www.cognitoforms.com/PacificScreening/ApplyOregonLandlordApplication" target="_blank">
        START LISTING WITH US TODAY
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: "BaseInfo",

  props: {
    title: String,
    dark: Boolean,
  },

  data: () => ({
    business: [
      {
        icon: "mdi-map-marker-outline",
        title: "Address",
        text: "8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137",
      },
      {
        icon: "mdi-cellphone",
        title: "Phone",
        text: "01 (800) 433 744<br>01 (800) 433 633",
      },
      {
        icon: "mdi-email",
        title: "Email",
        text: "john@vuetifyjs.com<br>heather@vuetifyjs.com",
      },
    ],
  }),
};
</script>
