<template>
    <div>
        <base-info-card
            v-if="!submitted"
            :title="title"
            :subtitle="subtitle"
            space="4"
            color="primary"
            class="pr-3"
        />
        <div v-if="submitted">
            <base-info-card
                title="Message Sent"
                subtitle="Thank you for your message"
                space="4"
                color="primary"
                class="pr-3"
                text="Your message has been sent to Rent Well. We will respond shortly. "
            />
        </div>
        <v-form v-if="!submitted" ref="form" v-model="valid" lazy-validation>
            <div class="pr-10">
                <div v-if="error">
                    <v-alert type="error">{{ error }}</v-alert>
                </div>
                <base-text-field
                    v-model="name"
                    label="Name"
                    :rules="nameRules"
                    required
                />

                <base-text-field
                    v-model="email"
                    label="Email"
                    :rules="emailRules"
                    required
                />

                <base-text-field
                    v-model="subject"
                    label="Subject"
                    :rules="subjectRules"
                    required
                />

                <base-textarea
                    v-model="message"
                    class="mb-6"
                    label="Your Message"
                    :rules="messageRules"
                    required
                />

                <base-text-field v-model="phone" label="Phone" hidden />
            </div>
            <base-btn
                :color="!theme.isDark ? 'accent' : 'white'"
                outlined
                target="_blank"
                @click="validate"
            >
                Send message
            </base-btn>
        </v-form>
    </div>
</template>

<script>
const axios = require("axios");
export default {
    name: "BaseContactForm",

    // Injected from the Vuetify Themeable mixin
    inject: ["theme"],

    props: {
        subtitle: String,
        title: {
            type: String,
            default: "MAIL US YOUR MESSAGE",
        },
    },
    data: () => ({
        valid: true,
        submitted: false,
        error: "",
        phone: "",
        name: "",
        nameRules: [
            (v) => !!v || "Name is required",
            (v) => (v && v.length >= 3) || "Name must be at least 3 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        subject: "",
        subjectRules: [
            (v) => !!v || "Subject is required",
            (v) =>
                (v && v.length >= 3) || "Subject must be at least 3 characters",
        ],
        message: "",
        messageRules: [
            (v) => !!v || "Message is required",
            (v) =>
                (v && v.length >= 3) || "Message must be at least 3 characters",
        ],
    }),
    methods: {
        async validate() {
            if (this.$refs.form.validate()) {
                try {
                    await axios.post(process.env.VUE_APP_SEND_MESSAGE_URL, {
                        name: this.name,
                        email: this.email,
                        subject: this.subject,
                        phone: this.phone,
                        message: this.message,
                    });
                    this.submitted = true;
                } catch (error) {
                    this.error = error;
                }
            }
        },
    },
};
</script>
<style>
.rw-input {
    display: none;
}
</style>
