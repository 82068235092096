<template>
    <v-text-field
        :class="hidden ? 'v-honey' : ''"
        outlined
        dense
        single-line
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot slot="append-outer" name="append-outer" />
    </v-text-field>
</template>

<script>
export default {
    name: "BaseTextField",
    props: {
        hidden: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="sass">
.v-honey
  visibility: hidden !important
  height: 0px
</style>
